<template>
	<w-layout fill-height ma-2>
		<w-flex>
			<w-iframe v-if="dashBoardLink" :src="dashBoardLink"></w-iframe>
		</w-flex>
	</w-layout>
</template>

<script>
import DashBoardModuleGuard from '@/mixins/ModulesGuards/DashBoard/DashBoardModuleGuard'
import { mapState } from 'vuex'

export default {
	name: 'DashBoard',
	components: {},
	mixins: [DashBoardModuleGuard],
	inject: ['holdingContext'],
	props: {
		dashBoard: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			dashBoardLink: null
		}
	},
	computed: {
		...mapState({
			darkMode: state => state.user.darkMode
		}),
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
	},
	watch: {},
	created: function () {
	},
	mounted: function () {
		this.loadDashBoard()
	},
	destroyed: function () {
	},
	methods: {
		loadDashBoard: function () {
			this.service.getDashBoard(this.holdingId, this.dashBoard).then(url => {
				this.dashBoardLink = this.darkMode ? url + "&theme=night" : url
			})
		}
	}
}
</script>
